input {
  text-overflow: ellipsis;
}
/* input CUSTOM */
.input_custom > div {
  position: relative;
}
.input_custom i {
  position: absolute;
  top: calc(50% - 12px);
  left: 13px;
  display: block;
}
.input_custom label {
  position: absolute;
  top: calc(50% - 7px);
  left: 13px;
  font-size: 14px;
  color: #9ca3af;
  font-weight: 500;
  line-height: 1;
  transition: all 0.3s;
}
.input_custom input {
  width: 100%;
  padding: 20px 12px 5px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  color: #111827;
  outline: none;
}
.input_custom input:disabled {
  color: #4b5563;
  background-color: #e5e7eb;
}
.input_custom input:not(.file_name):focus {
  border-color: #4ba9d8;
}
.input_custom input:not(.file_name):focus + label,
.input_custom input:not(:placeholder-shown) + label {
  top: 8px;
  font-size: 11px;
}
.valid_msg {
  font-size: 11px;
  color: #e71441;
  padding-top: 6px;
}

.input_custom.icon_type label {
  left: 46px;
}
.input_custom.icon_type input {
  padding-left: 46px;
}
.input_custom.icon_type .valid_msg {
  padding-left: 46px;
}
.input_custom .err_border input {
  border-color: #e71441;
}

/* input[type=file] CUSTOM */
.file_custom > div {
  display: flex;
  gap: 10px;
}
.file_custom .file_name:read-only,
.file_custom .file_name:disabled {
  background-color: #fff;
}
.file_custom .file_btn {
  position: relative;
}
.file_custom .file_btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  padding-left: 80px;
  opacity: 0;
  cursor: pointer;
}
