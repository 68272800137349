.main_visual{
  position: relative;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
.main_visual img{width:100%;}
.main_visual ul li{position:absolute; width:2.41%;}
.main_visual ul li:nth-of-type(1){top:7.1%; left:10.7%;}
.main_visual ul li:nth-of-type(2){top:10.2%; right:19.6%;}
.main_visual ul li:nth-of-type(3){top:40.7%; right:32.2%;}
.main_visual ul li:nth-of-type(4){top:20.8%; left:25.1%;}
.main_visual ul li:nth-of-type(5){bottom:27.6%; right:32.8%;}
.main_visual ul li:nth-of-type(6){bottom:32.4%; left:29.3%;}
.main_visual ul li:nth-of-type(7){top:0.4%; right:39.8%;}
.main_visual ul li:nth-of-type(8){top:36.8%; left:20.3%;}
.main_visual ul li:nth-of-type(9){top:46%; right:11.2%;}

.main_visual ul li:nth-of-type(1),
.main_visual ul li:nth-of-type(2),
.main_visual ul li:nth-of-type(3),
.main_visual ul li:nth-of-type(4),
.main_visual ul li:nth-of-type(5){animation:cmtkMove 2.5s ease-out 0s infinite;}
.main_visual ul li:nth-of-type(6),
.main_visual ul li:nth-of-type(7),
.main_visual ul li:nth-of-type(8),
.main_visual ul li:nth-of-type(9){animation:cmtkMove2 2.5s ease-out 0s infinite;}

/* animations */
@keyframes cmtkMove {
  0%, 75%, 100%{transform:translateY(0); -webkit-transform:translateY(0); -moz-transform:translateY(0); -ms-transform:translateY(0); -o-transform:translateY(0);}
  25%, 50%{transform:translateY(-100%); -webkit-transform:translateY(-100%); -moz-transform:translateY(-100%); -ms-transform:translateY(-100%); -o-transform:translateY(-100%);}
}
@keyframes cmtkMove2 {
  0%, 75%, 100%{transform:translateY(0); -webkit-transform:translateY(0); -moz-transform:translateY(0); -ms-transform:translateY(0); -o-transform:translateY(0);}
  25%, 50%{transform:translateY(100%); -webkit-transform:translateY(100%); -moz-transform:translateY(100%); -ms-transform:translateY(100%); -o-transform:translateY(100%);}
}

@media all and (max-width:767px) {
  .main_visual{
    width:calc(100% + 40px);
    margin-left:-20px;
  }
}
