/*===== FONTS =====*/
/* Pretendard */
@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff")
    format("woff");
}

/*===== STYLES =====*/
html,
body {
  font-family: "Pretendard";
}
