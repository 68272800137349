.ql-toolbar.ql-snow {
  border-color: #e5e7eb;
  border-radius: 6px 6px 0 0;
}
.ql-container.ql-snow {
  border-color: #e5e7eb;
  border-radius: 0 0 6px 6px;
}
.ql-editor {
  height: 240px;
}

@media all and (max-width: 767px) {
  .ql-editor {
    height: 180px;
  }
}
