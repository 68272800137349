@keyframes ldio-h7q8r85wa85 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-h7q8r85wa85 div {
  position: absolute;
  width: 58px;
  height: 58px;
  border: 12px solid #e5e7eb;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-h7q8r85wa85 div {
  animation: ldio-h7q8r85wa85 1s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-k1wpahvng3 {
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-h7q8r85wa85 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.6);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-h7q8r85wa85 div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
